

@media (max-width: 767px) {
  :root {
    --cache-version: 1;
  }
}


@media (max-width: 767px) {
  .hidden-mobile {
    display: none !important;
  }
}


@media (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
}


@media (max-width: 767px) {
  p {
    font: var(--font-mobile-body1);
  }

  strong {
    font: var(--font-mobile-body1-bold);
  }

  table thead {
    font: var(--font-mobile-body2-bold);
  }

  table tbody {
    font: var(--font-mobile-body2);
  }
}


@media (max-width: 767px) {
  .flash-message__list {
    padding: 0 var(--spacing-16);
  }
}


@media (max-width: 767px) {
  .flash-message__text {
    margin-top: var(--spacing-16);
    width: 100%;
  }
}


@media (max-width: 767px) {
  .footer__themes-title {
    font: var(--font-mobile-h4);
  }

  .footer__themes-logo img {
    display: none;
  }

  .footer__themes--bottom .footer__theme-link {
    font: var(--font-mobile-h5);
  }

  .footer__categories {
    order: 2;
  }

  .footer__categories--wrapper {
    gap: var(--spacing-48);
    flex-wrap: wrap;
  }

  .footer__categories--wrapper .footer__category {
    flex-basis: calc(50% - var(--spacing-24));
  }

  .footer__categories--wrapper .footer__category-main {
    font: var(--font-mobile-h4);
  }

  .footer__categories--wrapper .footer__subcategory {
    font: var(--font-mobile-meta-regular);
  }
}


@media (max-width: 767px) {
  .footer__contacts {
    order: 1;
  }

  .footer__contacts-links {
    font: var(--font-mobile-body2-bold);
    justify-content: center;
  }

  .footer__contacts-link {
    padding: var(--spacing-8) 0;
  }

  .footer__applications-wrapper {
    gap: var(--spacing-40);
    flex-direction: column;
    align-items: center;
  }

  .footer__applications {
    gap: var(--spacing-24);
  }
}


@media (max-width: 767px) {
  .footer__copyright {
    font: var(--font-mobile-meta-regular);
  }
}


@media (max-width: 767px) {
  .container--main {
    grid-template-rows: auto auto auto;
  }

  .container--main > .header-static {
    padding-left: var(--spacing-16);
    padding-right: var(--spacing-16);
  }

  .container--main > main {
    padding: var(--spacing-24) var(--spacing-16) var(--spacing-48);
  }

  .grid-233 {
    grid-template-columns: [lend] 1fr[cdivider] 1fr[rend];
  }
}


@media (max-width: 767px) {
  .header {
    padding: var(--spacing-8) var(--spacing-16);
  }

  .header .header__image {
    height: 36px;
  }
}


@media (max-width: 767px) {
  .header-logo {
    margin-right: auto;
    transition: height .2s;
  }

  .header-logo .header-logo__image {
    max-height: var(--spacing-header-mobile-brand-height);
  }
}


@media (max-width: 767px) {
  .header-desktop-navigation {
    display: none;
  }
}


@media (max-width: 767px) {
  .megamenu-wrapper {
    top: var(--spacing-64);
    padding: var(--spacing-32) var(--spacing-16);
    row-gap: var(--spacing-32);
    grid-template: [r1-start] "search" max-content [r1-end]
                   [r2-start] "categories" max-content [r2-end]
                   [r3-start] "stickers" max-content [r3-end]
                   [r4-start] "print" max-content [r4-end]
                   [r5-start] "divider" max-content [r5-end]
                   [r6-start] "applications" max-content [r6-end]
                   [r7-start] "social" max-content [r7-end]
                   [r8-start] "contact" max-content [r8-end]
                   / 1fr;
  }

  .megamenu-wrapper.opened {
    max-width: 100%;
  }
}


@media (max-width: 767px) {
  .megamenu-close {
    display: none;
  }
}


@media (max-width: 767px) {
  .megamenu-stickers__item, .megamenu-stickers__item > a, .megamenu-stickers__item > span {
    font: var(--font-mobile-meta-regular);
  }
}


@media (max-width: 767px) {
  .megamenu-categories {
    gap: var(--spacing-24);
    row-gap: var(--spacing-32);
    flex-flow: wrap;
  }

  .megamenu-categories__item {
    row-gap: var(--spacing-12);
    flex-basis: calc(50% - var(--spacing-12));
  }

  .megamenu-categories__item-title {
    font: var(--font-mobile-h4);
  }

  .megamenu-categories .megamenu-subcategories {
    gap: var(--spacing-8);
    font: var(--font-mobile-body2);
  }
}


@media (max-width: 767px) {
  .megamenu-print {
    gap: var(--spacing-16);
  }

  .megamenu-print__title {
    font: var(--font-mobile-h4);
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .megamenu-print__subtitle {
    font: var(--font-mobile-body1);
  }

  .megamenu-print__covers {
    gap: var(--spacing-24);
    flex-wrap: wrap;
  }
}


@media (max-width: 767px) {
  .megamenu-logo {
    display: none;
  }
}


@media (max-width: 767px) {
  .megamenu-applications {
    flex-direction: column;
    align-items: center;
  }

  .megamenu-applications__wrapper {
    align-items: center;
  }

  .megamenu-applications__title {
    font: var(--font-mobile-h4);
  }

  .megamenu-applications__subtitle {
    font: var(--font-mobile-body2);
  }
}


@media (max-width: 767px) {
  .megamenu-social {
    justify-content: center;
  }
}


@media (max-width: 767px) {
  .megamenu-contact {
    align-items: center;
  }

  .megamenu-contact__title {
    font: var(--font-mobile-h4);
  }

  .megamenu-contact__email {
    font: var(--font-mobile-body2);
  }
}


@media (max-width: 767px) {
  .megamenu-newsletter {
    align-items: center;
  }

  .megamenu-newsletter__title {
    font: var(--font-mobile-h4);
  }

  .megamenu-newsletter__email {
    font: var(--font-mobile-body2);
    padding-bottom: 80px;
  }
}


@media (max-width: 767px) {
  .typography--h1, .typography--heading-3xl {
    font: var(--typography-mobile-heading-3xl-font-size) / var(--typography-mobile-heading-3xl-line-height) var(--heading-3xl-font-family);
  }

  .typography--heading-2xl {
    font: var(--typography-mobile-heading-2xl-font-size) / var(--typography-mobile-heading-2xl-line-height) var(--heading-2xl-font-family);
  }

  .typography--h2, .typography--heading-xl {
    font: var(--typography-mobile-heading-xl-font-size) / var(--typography-mobile-heading-xl-line-height) var(--heading-xl-font-family);
  }

  .typography--h3, .typography--heading-lg {
    font: var(--typography-mobile-heading-lg-font-size) / var(--typography-mobile-heading-lg-line-height) var(--heading-lg-font-family);
  }

  .typography--h4, .typography--heading-md {
    font: var(--typography-mobile-heading-md-font-size) / var(--typography-mobile-heading-md-line-height) var(--heading-md-font-family);
  }

  .typography--h5, .typography--heading-sm {
    font: var(--typography-mobile-heading-sm-font-size) / var(--typography-mobile-heading-sm-line-height) var(--heading-sm-font-family);
  }

  .typography--heading-xs {
    font: var(--typography-mobile-heading-xs-font-size) / var(--typography-mobile-heading-xs-line-height) var(--heading-xs-font-family);
  }

  .typography--heading-2xs {
    font: var(--typography-mobile-heading-2xs-font-size) / var(--typography-mobile-heading-2xs-line-height) var(--heading-2xs-font-family);
  }

  .typography--inter-title {
    font: var(--typography-mobile-inter-title-font-size) / var(--typography-mobile-inter-title-line-height) var(--inter-title-font-family);
  }

  .typography--section-title {
    font: var(--typography-mobile-section-title-font-size) / var(--typography-mobile-section-title-line-height) var(--section-title-font-family);
  }

  .typography--subtitle1, .typography--subtitle-lg {
    font: var(--typography-mobile-subtitle-lg-font-size) / var(--typography-mobile-subtitle-lg-line-height) var(--subtitle-lg-font-family);
  }

  .typography--subtitle1-bold, .typography--subtitle-lg-bold {
    font: 700 var(--typography-mobile-subtitle-lg-font-size) / var(--typography-mobile-subtitle-lg-line-height) var(--subtitle-lg-bold-font-family);
  }

  .typography--subtitle2, .typography--subtitle-md {
    font: var(--typography-mobile-subtitle-md-font-size) / var(--typography-mobile-subtitle-md-line-height) var(--subtitle-md-font-family);
  }

  .typography--subtitle2-bold, .typography--subtitle-md-bold {
    font: var(--typography-mobile-subtitle-md-bold-font-size) / var(--typography-mobile-subtitle-md-line-height) var(--subtitle-md-bold-font-family);
  }

  .typography--body1, .typography--body-lg {
    font: var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-font-family);
  }

  .typography--body1-bold, .typography--body-lg-bold {
    font: 700 var(--typography-mobile-body-lg-font-size) / var(--typography-mobile-body-lg-line-height) var(--body-lg-bold-font-family);
  }

  .typography--body2, .typography--body-md {
    font: var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-font-family);
  }

  .typography--body2-bold, .typography--body-md-bold {
    font: 700 var(--typography-mobile-body-md-font-size) / var(--typography-mobile-body-md-line-height) var(--body-md-bold-font-family);
  }

  .typography--meta-regular, .typography--body-sm {
    font: var(--typography-mobile-body-sm-font-size) / var(--typography-mobile-body-sm-line-height) var(--body-sm-font-family);
  }

  .typography--meta-bold, .typography--body-sm-bold {
    font: 700 var(--typography-mobile-body-sm-font-size) / var(--typography-mobile-body-sm-line-height) var(--body-sm-bold-font-family);
  }

  .typography--body-xs {
    font: var(--typography-mobile-body-xs-font-size) / var(--typography-mobile-body-xs-line-height) var(--body-xs-font-family);
  }

  .typography--body-xs-bold {
    font: 700 var(--typography-mobile-body-xs-font-size) / var(--typography-mobile-body-xs-line-height) var(--body-xs-bold-font-family);
  }

  .typography--button-large, .typography--button-lg {
    font: var(--typography-mobile-button-lg-font-size) / var(--typography-mobile-button-lg-line-height) var(--button-lg-font-family);
  }

  .typography--button-medium, .typography--button-md {
    font: var(--typography-mobile-button-md-font-size) / var(--typography-mobile-button-md-line-height) var(--button-md-font-family);
  }

  .typography--button-small, .typography--button-sm {
    font: var(--typography-mobile-button-sm-font-size) / var(--typography-mobile-button-sm-line-height) var(--button-sm-font-family);
  }

  .typography--menu-item {
    font: var(--typography-mobile-menu-item-font-size) / var(--typography-mobile-menu-item-line-height) var(--menu-item-font-family);
  }

  .typography--label {
    font: var(--typography-mobile-label-font-size) / var(--typography-mobile-label-line-height) var(--label-font-family);
  }

  .typography--helper-text {
    font: var(--typography-mobile-helper-text-font-size) / var(--typography-mobile-helper-text-line-height) var(--helper-text-font-family);
  }
}


@media (max-width: 767px) {
  .accordion__answer * {
    font: var(--font-mobile-body2);
  }
}


@media (max-width: 767px) {
  .accordion__answer h3, .accordion__answer h4 {
    font: var(--font-mobile-body2-bold);
  }
}


@media (max-width: 767px) {
  .meta {
    font: var(--font-mobile-meta-regular);
  }
}


@media (max-width: 767px) {
  .category {
    font: var(--font-mobile-meta-bold);
  }
}


@media (max-width: 767px) {
  .article-card, .article-card__container {
    gap: var(--spacing-12);
  }

  .article-card__content {
    gap: var(--spacing-4);
  }

  .article-card--mobile-portrait-xl .article-card__title, .article-card--mobile-landscape-xl .article-card__title {
    font: var(--font-mobile-h3);
  }

  .article-card--mobile-portrait-xl .article-card__rating .rating-bar__icons svg, .article-card--mobile-landscape-xl .article-card__rating .rating-bar__icons svg {
    width: 24px;
    height: 24px;
  }

  .article-card--mobile-portrait-lg .article-card__title, .article-card--mobile-landscape-lg .article-card__title, .article-card--mobile-portrait-md .article-card__title, .article-card--mobile-landscape-md .article-card__title {
    font: var(--font-mobile-h3);
  }

  .article-card--mobile-portrait-xs .article-card__title, .article-card--mobile-landscape-xs .article-card__title {
    font: var(--font-mobile-h5);
  }

  .article-card--mobile-landscape-xs .article-card__image {
    flex: 0 0 115px;
    max-width: 50%;
  }

  .article-card--mobile-landscape-xl .article-card__container, .article-card--mobile-landscape-lg .article-card__container, .article-card--mobile-landscape-md .article-card__container, .article-card--mobile-landscape-sm .article-card__container, .article-card--mobile-landscape-xs .article-card__container, .article-card--mobile-landscape-xxs .article-card__container {
    flex-direction: row;
  }

  .article-card--mobile-landscape-xl .article-card__container--reverse, .article-card--mobile-landscape-lg .article-card__container--reverse, .article-card--mobile-landscape-md .article-card__container--reverse, .article-card--mobile-landscape-sm .article-card__container--reverse, .article-card--mobile-landscape-xs .article-card__container--reverse, .article-card--mobile-landscape-xxs .article-card__container--reverse {
    flex-direction: row-reverse;
  }

  .article-card--mobile-portrait-xl .article-card__container, .article-card--mobile-portrait-lg .article-card__container, .article-card--mobile-portrait-md .article-card__container, .article-card--mobile-portrait-sm .article-card__container, .article-card--mobile-portrait-xs .article-card__container, .article-card--mobile-portrait-xxs .article-card__container {
    flex-direction: column;
  }

  .article-card--mobile-portrait-xl .article-card__container--reverse, .article-card--mobile-portrait-lg .article-card__container--reverse, .article-card--mobile-portrait-md .article-card__container--reverse, .article-card--mobile-portrait-sm .article-card__container--reverse, .article-card--mobile-portrait-xs .article-card__container--reverse, .article-card--mobile-portrait-xxs .article-card__container--reverse {
    flex-direction: column-reverse;
  }
}


@media (max-width: 767px) {
  .article-card--promoted {
    width: calc(100% + var(--spacing-8));
    margin: calc(-1 * var(--spacing-4));
  }
}


@media (max-width: 767px) {
  .author {
    margin-top: var(--spacing-16);
  }
}


@media (max-width: 767px) {
  .author__names {
    flex-direction: column;
    display: flex;
  }

  .author__separator {
    display: none;
  }
}


@media (max-width: 767px) {
  .author-detail-wrapper {
    grid-column: lend / rend;
  }

  .author-card--author-detail {
    padding-bottom: var(--spacing-32);
  }

  .author-card--author-detail .author-card__name-wrapper, .author-card--author-detail .author-card__role {
    grid-column: 2 span;
  }

  .author-card--author-detail .social-media {
    margin-top: var(--spacing-24);
    grid-area: 4 / 3 span;
  }

  .author-card--author-detail .author-card__mail-link {
    margin-top: var(--spacing-8);
    grid-area: 5 / 3 span;
  }
}


@media (max-width: 767px) {
  .breadcrumb {
    grid-column: lend / rend;
    margin-bottom: -8px;
  }
}


@media (max-width: 767px) {
  .button {
    font: var(--font-mobile-button-large);
  }
}


@media (max-width: 767px) {
  .button--md {
    font: var(--font-mobile-button-medium);
  }
}


@media (max-width: 767px) {
  .button--sm {
    font: var(--font-mobile-button-small);
  }
}


@media (max-width: 767px) {
  .load-more-disabled__title {
    font: var(--font-mobile-h4);
  }

  .load-more-disabled__subtitle {
    font: var(--font-mobile-body2);
  }
}


@media (max-width: 767px) {
  .partner-promo__partner {
    font: var(--font-mobile-meta-bold);
  }
}


@media (max-width: 767px) {
  .promobox {
    margin-left: calc(-1 * var(--spacing-16));
    margin-right: calc(-1 * var(--spacing-16));
    border-top: 0;
    border-left: 0;
    border-right: 0;
    padding-top: 0;
  }
}


@media (max-width: 767px) {
  .promobox__main-article .article-card__description {
    display: none;
  }
}


@media (max-width: 767px) {
  .promobox--horizontal, .promobox--horizontal .article-card__container--reverse {
    flex-direction: column;
  }
}


@media (max-width: 767px) {
  .promobox--horizontal .promobox__left-article .article-card__image {
    order: 1;
  }

  .promobox--horizontal .promobox__left-article .article-card__title {
    order: 2;
  }

  .promobox--horizontal .promobox__left-article .article-card__content {
    order: 3;
  }
}


@media (max-width: 767px) {
  .promobox--horizontal .promobox__right-articles .article-card__title {
    font: var(--font-mobile-h3);
  }
}


@media (max-width: 767px) {
  .enquiry-bars__title {
    font: var(--font-mobile-body2-bold);
  }

  .enquiry-bars__question {
    font: var(--font-mobile-h2);
  }

  .enquiry-bars-answers__answer {
    font: var(--font-mobile-body1);
  }

  .enquiry-bars-footer__vote-notice {
    font: var(--font-mobile-body1-bold);
  }

  .enquiry-bars-footer__total-votes {
    font: var(--font-mobile-body1);
  }
}


@media (max-width: 767px) {
  .print__cover {
    gap: var(--spacing-16);
  }

  .print__cover > a > img {
    width: 106px;
    height: auto;
  }

  .print__cover .button {
    width: 106px;
  }
}


@media (max-width: 767px) {
  .promo-subscription {
    width: 300px;
    margin: 0 auto;
  }
}


@media (max-width: 767px) {
  .premium-card {
    padding: var(--spacing-32) var(--spacing-24);
  }
}


@media (max-width: 767px) {
  .premium-card--best {
    order: -1;
  }
}


@media (max-width: 767px) {
  .topic__image img {
    max-height: 68px;
  }
}


@media (max-width: 767px) {
  .topic__top {
    flex-direction: column;
    align-items: flex-start;
  }
}


@media (max-width: 767px) {
  .topic__link .typography {
    font: var(--font-mobile-h2);
  }
}


@media (max-width: 767px) {
  .topic__top-articles {
    flex-direction: column;
  }
}


@media (max-width: 767px) {
  .topic__top-articles__item {
    width: 100%;
  }
}


@media (max-width: 767px) {
  .topic__top-articles__item:not(:first-of-type) .article-card__image {
    display: none;
  }
}


@media (max-width: 767px) {
  .topic__top-articles__item:nth-of-type(3) {
    display: block;
  }
}


@media (max-width: 767px) {
  .topic__custom-article p {
    font: var(--font-mobile-body1);
  }

  .topic__custom-article strong {
    font: var(--font-mobile-body1-bold);
  }

  .topic__custom-article table thead {
    font: var(--font-mobile-body2-bold);
  }

  .topic__custom-article table tbody {
    font: var(--font-mobile-body2);
  }
}


@media (max-width: 767px) {
  .topic--topicInHP .topic__top-articles .article-card__image {
    flex: auto;
  }
}


@media (max-width: 767px) {
  .topic--topicInHP2x2 .topic__top-articles {
    flex-direction: column;
  }
}


@media (max-width: 767px) {
  .topic--topicInHP2x2 .topic__top-articles .topic__top-articles__item {
    width: 100%;
  }
}


@media (max-width: 767px) {
  .tabs-and-links__link {
    flex-basis: 100%;
  }
}


@media (max-width: 767px) {
  .tabs-and-links__link a {
    padding: var(--spacing-8);
  }
}


@media (max-width: 767px) {
  .tag, .tag a, .tag span {
    font: var(--font-mobile-meta-regular);
  }
}


@media (max-width: 767px) {
  .ad-position--mobile {
    display: block;
  }

  .ad-category--left-column {
    grid-column: 1 / -1;
  }
}


@media (max-width: 767px) {
  .wrapper--main > .grid-233 > aside {
    display: none;
  }
}


@media (max-width: 767px) {
  .modal__wrapper {
    padding: var(--spacing-16);
    width: calc(100% - var(--spacing-32));
    gap: var(--spacing-16);
  }
}


@media (max-width: 767px) {
  .modal__title {
    font: var(--font-mobile-h3);
  }
}


@media (max-width: 767px) {
  .modal__close {
    top: var(--spacing-16);
    right: var(--spacing-16);
  }
}


@media (max-width: 767px) {
  .modal__footer {
    justify-content: initial;
    align-items: center;
    gap: var(--spacing-16);
  }

  .modal__footer a, .modal__footer button {
    width: 100%;
  }
}


@media (max-width: 767px) {
  .box__headline {
    justify-content: space-between;
  }
}


@media (max-width: 767px) {
  .wiki-search {
    grid-column: lend / rend;
  }

  .wiki-search__title {
    font: var(--font-mobile-h2);
  }
}


@media (max-width: 767px) {
  .category-info__description p {
    font: var(--font-mobile-body1);
  }

  .category-info__description strong {
    font: var(--font-mobile-body1-bold);
  }

  .category-info__description table thead {
    font: var(--font-mobile-body2-bold);
  }

  .category-info__description table tbody {
    font: var(--font-mobile-body2);
  }
}


@media (max-width: 767px) {
  .category-info {
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .category-info__menu {
    margin: var(--spacing-32) 0 var(--spacing-24);
    font: var(--font-desktop-body2);
    color: var(--text-primary);
    width: 100%;
    height: 44px;
    position: relative;
    overflow: visible;
  }

  .category-info__menu > ul {
    z-index: 2;
    padding: var(--spacing-12) var(--spacing-48) var(--spacing-12) var(--spacing-16);
    border: 1px solid var(--other-outline-border);
    background: var(--background-paper-contrast);
    border-radius: 4px;
    width: 100%;
    min-height: 44px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  .category-info__menu > ul:before {
    content: "Menu";
    top: var(--spacing-12);
    left: var(--spacing-16);
    position: absolute;
  }

  .category-info__menu > ul:after {
    content: "";
    top: 18px;
    right: calc(var(--spacing-48) / 2 - 6px);
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMiAxLjQxIDEwLjU5IDAgNiA0LjU4IDEuNDEgMCAwIDEuNDFsNiA2IDYtNloiIGZpbGw9IiMwMDAiLz48L3N2Zz4=") no-repeat;
    width: 12px;
    height: 8px;
    transition: transform .2s ease-in-out;
    display: block;
    position: absolute;
    transform: rotateX(0);
  }

  .category-info__menu > ul li {
    display: none;
  }

  .category-info__menu--opened > ul {
    border: 2px solid var(--primary-main);
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .category-info__menu--opened > ul:after {
    transition: transform .2s ease-in-out;
    transform: rotateX(180deg);
  }

  .category-info__menu--opened > ul li {
    background: var(--background-paper-contrast);
    flex: 1;
    display: block;
    position: relative;
  }

  .category-info__menu--opened > ul li:hover {
    background: var(--action-hover);
  }

  .category-info__menu--opened > ul li:hover > a {
    color: var(--primary-main);
  }

  .category-info__menu--opened > ul > li:first-child {
    margin-top: var(--spacing-32);
  }

  .category-info__menu-item--active {
    border-left: 2px solid var(--primary-main);
  }

  .category-info__menu-link {
    padding: var(--spacing-12) 0 var(--spacing-12) var(--spacing-16);
    color: var(--text-primary);
    width: 100%;
    text-decoration: none;
    display: block;
  }

  .category-info span.category-info__menu-link {
    display: none;
  }

  .category-info h1 {
    font: var(--font-mobile-h2);
  }
}


@media (max-width: 767px) {
  .profile-user {
    width: 100%;
  }

  .profile-user strong {
    font: var(--font-mobile-body1-bold);
  }

  .profile-user__cancel-reccuring strong .recurring-info-popup, .profile-user__cancel-newsletter strong .recurring-info-popup {
    font: var(--font-mobile-meta-regular);
  }

  .profile-user__title h3 {
    margin-top: 5px;
  }

  .profile-user .activation-steps__info {
    display: none;
  }

  .profile-user .activation-steps__item:before {
    border: 21px solid #0000;
    border-left: 21px solid var(--grey-200);
  }

  .profile-user .activation-steps__item.active-step:before {
    border-left: 21px solid var(--grey-50);
  }

  .profile-user .block__body-content .premium-offer {
    display: block;
  }

  .profile-user .block__body-content__center {
    align-self: initial;
    flex-direction: column-reverse;
  }

  .profile-user__voucher-title {
    font: var(--font-mobile-body1-bold);
  }

  .form-review__field label, .form-review__field textarea {
    font: var(--font-mobile-meta-regular);
  }
}


@media (max-width: 767px) {
  .thanks {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}


@media (max-width: 767px) {
  .thanks__title {
    margin-bottom: var(--spacing-24);
  }
}


@media (max-width: 767px) {
  .thanks__text {
    margin-bottom: var(--spacing-24);
  }
}


@media (max-width: 767px) {
  .thanks__greetings {
    margin-bottom: var(--spacing-4);
  }
}


@media (max-width: 767px) {
  .thanks__image-wrapper {
    width: 100%;
  }
}


@media (max-width: 767px) {
  .newsletters__bar--content {
    padding-left: var(--spacing-12);
    padding-right: var(--spacing-32);
  }
}


@media (max-width: 767px) {
  .newsletters__bar--summary span {
    margin-right: var(--spacing-56);
    text-wrap: wrap;
    padding-left: 0;
  }

  .newsletters__bar--summary span:before {
    display: none;
  }

  .newsletters__bar--summary span:after {
    content: ", ";
  }
}


@media (max-width: 767px) {
  .newsletters__bar--summary {
    flex-direction: column;
  }

  .newsletter__detail {
    padding-bottom: var(--spacing-64);
  }

  .newsletter__detail--signup {
    top: auto;
    bottom: var(--spacing-24);
  }
}


@media (max-width: 767px) {
  .list-articles-wrapper--vertical, .list-articles-wrapper--horizontal {
    gap: var(--spacing-24);
  }
}


@media (max-width: 767px) {
  .list-articles-wrapper--timeline {
    gap: var(--spacing-16);
  }
}


@media (max-width: 767px) {
  .list-articles__timeline {
    gap: var(--spacing-8);
    flex-direction: column;
  }
}


@media (max-width: 767px) {
  .list-articles__timeline .article-card__image {
    flex: 0 0 80px;
  }
}


@media (max-width: 767px) {
  .list-articles__timeline-time {
    line-height: 1;
  }
}


@media (max-width: 767px) {
  .topic-banner {
    row-gap: var(--spacing-8);
  }
}


@media (max-width: 767px) {
  .topic-banner__label {
    margin: var(--spacing-6) 0;
  }
}


@media (max-width: 767px) {
  .topic-banner__title {
    order: 3;
  }
}


@media (max-width: 767px) {
  .topic-banner__arrow {
    order: 2;
    position: relative;
    right: 0;
  }
}


@media (max-width: 767px) {
  .premium-offer {
    flex-direction: column;
    align-items: center;
  }
}


@media (max-width: 767px) {
  .premium-print {
    padding: var(--spacing-24) var(--spacing-24);
  }
}


@media (max-width: 767px) {
  .list-articles-wrapper--2x2 {
    flex-direction: column;
  }
}


@media (max-width: 767px) {
  .list-articles-wrapper--2x2 .article-card {
    width: 100%;
  }
}


@media (max-width: 767px) {
  .list-article-comments__next-link {
    font: var(--font-mobile-button-small);
  }
}


@media (max-width: 767px) {
  .list-article-author .article-author__image img, .list-article-author .article-author__image svg, .list-article-author .article-author__image picture {
    width: 88px;
    height: 88px;
  }
}


@media (max-width: 767px) {
  .list-article-author .article-author .article-author__title {
    gap: var(--spacing-8);
    font: var(--font-mobile-h4);
  }
}


@media (max-width: 767px) {
  .list-article-author .article-author__name {
    font: var(--font-mobile-h5);
  }
}


@media (max-width: 767px) {
  .list-article-author .article-author__read-more {
    font: var(--font-mobile-meta-bold);
  }
}


@media (max-width: 767px) {
  .list-article-author .article-author__white-line {
    min-height: 44px;
  }
}


@media (max-width: 767px) {
  .list-article-author-total-2 {
    gap: var(--spacing-16);
  }

  .list-article-author-total-2:after {
    content: "";
    background: linear-gradient(90deg, #0000 0%, #ffffff80 50%, #fff 100%);
    width: 124px;
    height: 370px;
    display: block;
    position: absolute;
    right: 0;
  }

  .list-article-author-total-2.hide-after:after {
    opacity: 0;
  }

  .list-article-author-total-2 > .article-author {
    min-width: 224px;
  }
}


@media (max-width: 767px) {
  .container--main > main {
    gap: var(--spacing-24);
  }
}


@media (max-width: 767px) {
  .grid-233 {
    gap: var(--spacing-24);
  }
}


@media (max-width: 767px) {
  .megamenu-wrapper {
    grid-template: [r1-start] "search" max-content [r1-end]
                   [r2-start] "categories" max-content [r2-end]
                   [r3-start] "stickers" max-content [r3-end]
                   [r4-start] "print" max-content [r4-end]
                   [r5-start] "divider" max-content [r5-end]
                   [r6-start] "applications" max-content [r6-end]
                   [r7-start] "social" max-content [r7-end]
                   [r8-start] "newsletter" max-content [r8-end]
                   / 1fr;
  }
}